"use client";

import { CheckLoginStatus } from "@/utils/Hooks/checkLoginStatus";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";
const MobileMenu = ({
  isOpen,
  setIsOpen,
  setIsModalOpen
}: any) => {
  const {
    push
  } = useRouter();
  const {
    data: checkLogin,
    isLoading: isLoginLoading
  } = CheckLoginStatus();
  return <div className={`mob-menu-container ${isOpen ? "open" : ""}`} data-sentry-component="MobileMenu" data-sentry-source-file="Mobile-Menu.tsx">
      <div onClick={() => setIsOpen(false)} className="bg-overlay"></div>
      <div className="mob-menu">
        <div className="">
          <ul>
            <li onClick={() => {
            push("https://home.growthschool.io/b2b");
            setIsOpen(false);
          }}>
              <div className="section__navbar-link">Upskill your team</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="Mobile-Menu.tsx">
                <path d="M10 16L14 12L10 8" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Mobile-Menu.tsx" />
              </svg>
            </li>
            <li onClick={() => {
            push("/in/about-us");
            setIsOpen(false);
          }}>
              <div className="section__navbar-link">About Us</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-source-file="Mobile-Menu.tsx">
                <path d="M10 16L14 12L10 8" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Mobile-Menu.tsx" />
              </svg>
            </li>
            {/* <li
              onClick={(e) => {
                push("/in/membership");
                e.preventDefault();
                // do this or no
                // setIsModalOpen(true);
                setIsOpen(false);
              }}
             >
              <div className="section__navbar-link">Membership</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 16L14 12L10 8"
                  stroke="#002726"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
             </li> */}
          </ul>
          <ul className="top-list">
            <li>
              <Link href="https://serv-learner-prod.growthschool.io/auth/login" className="section__navbar-button" data-sentry-element="Link" data-sentry-source-file="Mobile-Menu.tsx">
                {isLoginLoading || !checkLogin || checkLogin.isLogin === undefined ? "Loading..." : checkLogin.isLogin ? "Dashboard" : "Login"}
              </Link>
            </li>
            <li>
              <Link href="https://forms.growthschool.io/custom/apply-as-a-mentor-at-growthschool" className="section__navbar-button " target="_blank" data-sentry-element="Link" data-sentry-source-file="Mobile-Menu.tsx">
                Apply as Mentor
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>;
};
export default MobileMenu;
const getStorageMechanism = () => {
  if (typeof window !== "undefined") {
    return window.localStorage;
  }
  return null;
};
export const setItem = (key: string, value: any) => {
  const storage = getStorageMechanism();
  if (storage) {
    storage.setItem(key, value);
  }
};
export const getItem = (key: string) => {
  const storage = getStorageMechanism();
  if (storage) {
    const value = storage.getItem(key);
    if (value) {
      return value;
    }
    return null;
  }
};
export const removeItem = (key: string) => {
  const storage = getStorageMechanism();
  if (storage) {
    storage.removeItem(key);
  }
};
export const clearStorage = async () => {
  const storage = getStorageMechanism();
  if (storage) {
    await storage.clear();
  }
};
"use client";

import { FooterDocument } from "@/gql/graphql";
import { setItem } from "@/utils/Functions/storageUtils";
import { RouteStrings, routeVariables, sanitizeUrlSegment } from "@/utils/routestrings";
import { useGraphQL } from "@/utils/useGraphQL";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useEffect, useState, useMemo } from "react";
import GSfacebook from "../../../../public/images/new/GS-Facebook-White.svg";
import GSLinkedwhite from "../../../../public/images/new/GS-LinkedIn-White.svg";
import GSLogoOutline from "../../../../public/images/new/GS-Logo-Outline.svg";
import GSXwhite from "../../../../public/images/new/GS-X-White.svg";
import ModalComponent from "../Common/Modal";
import { getSlugAndRouteName } from "@/utils/Functions";
import { fetchDiscoveryData, fetchIndustriesData } from "@/services/external";
import { Industry, Program, StandardLP } from "@/services/types";
export default function Footer() {
  const {
    data: footerData
  } = useGraphQL(FooterDocument);
  const params = useParams();
  const {
    isPremium
  } = getSlugAndRouteName(params?.route?.toString());
  interface FormValues {
    email: string;
    fullName: string;
    industry: string;
  }
  interface FormErrors {
    email?: string;
    fullName?: string;
    industry?: string;
  }
  const {
    data: learnerData
  } = useQuery<Program[]>({
    queryKey: ["learner-api"],
    queryFn: () => fetchDiscoveryData()
  });
  const {
    data: industries
  } = useQuery<Industry[]>({
    queryKey: ["industries"],
    queryFn: () => fetchIndustriesData()
  });
  const defaultIndustry = "Business";
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    fullName: "",
    industry: industries ? industries[0].attributes.name : defaultIndustry
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const programs = useMemo(() => {
    if (!industries) return undefined;
    const temp: {
      [key: number]: StandardLP[];
    } = {};
    industries.forEach(industry => {
      temp[industry.id] = [];
      industry.attributes.premium_subindustries.data?.forEach(subindustry => {
        subindustry.attributes.standard_lps.data?.forEach(program => {
          temp[industry.id].push(program);
        });
      });
    });

    // Remove duplicate programs from each industry
    for (let industryId in temp) {
      const seenProductIds = new Set();
      temp[industryId] = temp[industryId].filter(program => !seenProductIds.has(program.attributes.productId) && seenProductIds.add(program.attributes.productId));
    }
    return temp;
  }, [industries]);
  const discoveryPrograms = useMemo(() => {
    if (!learnerData || learnerData.length === 0) return undefined;
    return learnerData.reduce((acc, program) => {
      acc[program.product_id] = program;
      return acc;
    }, ({} as {
      [key: number]: Program;
    }));
  }, [learnerData]);
  const mentorshipTypes = ["Intensive Program", "Career Accelerator Program"];
  const selfPacedTypes = ["OnDemand"];
  const filterByProgramTypes = (programTypes: any, index: number) => {
    return programs && discoveryPrograms && programs[index] ? Array.from(new Set(programs[index].filter(item => programTypes.includes(discoveryPrograms[item.attributes.productId].programType))
    // .map((item) => discoveryPrograms[item.attributes.productId])
    )) : [];
  };
  const subCategories: {
    [key: string]: Program[];
  } = {};
  learnerData?.forEach((learner: any) => {
    learner.subIndustries.map((subCategory: any) => {
      const subCategoriesLcl = subCategories[subCategory.subIndustry];
      if (!subCategoriesLcl) {
        subCategories[subCategory.subIndustry] = [learner];
      } else subCategories[subCategory.subIndustry].push(learner);
    });
  });
  const freeIndustries = footerData?.industries?.data;
  const [isNewsletterModal, setIsNewsletterModal] = useState(false);
  const [isNewsletterSuccessModal, setIsNewsletterSuccessModal] = useState(false);

  // hook to close the pop up when esc is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsNewsletterModal(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const validateForm = (submit: boolean, values: FormValues) => {
    let errors: FormErrors = {};
    if (!values.fullName.trim()) {
      errors.fullName = "Name field can't be empty";
    }
    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Must be a valid email";
    }
    if (values.industry === defaultIndustry) {
      errors.industry = "Industry needs to be selected";
    }
    setFormErrors(errors);
    if (submit && Object.keys(errors).length === 0) {
      setIsNewsletterModal(false);
      setIsNewsletterSuccessModal(true);
      setFormValues({
        email: "",
        fullName: "",
        industry: defaultIndustry
      });
      setFormErrors({});
    }
  };
  return <footer className={`section__footer section__bg ${isPremium ? "section__footer--slp" : ""}`} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="section__footer-container section__container--small">
        <div className="section__footer-header">
          <div className="section__footer-header-left">
            <Link href="/" className="section__footer-header-logo" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <Image src={GSLogoOutline} alt="GS Logo Outline" className="section__footer-header-logo-img" width={150} height={50} priority data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
            </Link>
            <div className="section__footer-header-socials">
              <Link href="https://www.facebook.com/growthschoolio" target="_blank" className="section__footer-header-socials-link" rel="me" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                <Image src={GSfacebook} alt="GS Facebook White" className="section__footer-header-socials-link-img" loading="lazy" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
              </Link>
              <Link href="https://x.com/growthschoolio" target="_blank" className="section__footer-header-socials-link" rel="me" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                <Image src={GSXwhite} alt="GS X White" className="section__footer-header-socials-link-img" loading="lazy" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
              </Link>
              <Link href="https://www.linkedin.com/school/growthschoolio/" target="_blank" className="section__footer-header-socials-link" rel="me" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                <Image src={GSLinkedwhite} alt="GS LinkedIn White" className="section__footer-header-socials-link-img" loading="lazy" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
              </Link>
            </div>
          </div>
          <div className="section__footer-header-right">
            <h6>Best kept secrets ✨</h6>
            <p>
              Be the first to find out about the newest programs, workshops, and
              community activities - plus industry news, articles, and special
              reports.{" "}
            </p>
            <div className="section__footer-header-form">
              <input type="email" placeholder="Enter your email" className="section__footer-header-form-input" name="email" value={formValues?.email} onChange={e => setFormValues(prevValues => ({
              ...prevValues,
              ["email"]: e.target.value
            }))} />
              <button onClick={() => {
              setIsNewsletterModal(true);
            }} className="section__footer-header-form-button">
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <hr className="w-full h-px bg-white opacity-20" />

        <div className="grid grid-cols-1 gap-8 pl-0 text-white md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 lg:px-0">
          {industries && industries.length > 0 && industries.map((industry, index) => filterByProgramTypes(mentorshipTypes, industry.id).length > 0 && <div key={index} className="space-y-4">
                    <h6 className="text-lg font-medium">
                      {industry.attributes.name}
                    </h6>
                    <ul className="space-y-2 text-sm">
                      {filterByProgramTypes(mentorshipTypes, industry.id)?.map((program, itemIndex) => <li key={itemIndex} className="inline md:block md:before:content-none before:content-['•'] before:mx-2 opacity-60 hover:opacity-80 transition-opacity duration-300">
                            <Link href={`/in/${sanitizeUrlSegment(program.attributes.name)}${routeVariables.PREMIUMCOURSE[0]}${program.attributes.productId}`} onClick={() => {
                setItem("Industry", industry.attributes.name);
                setItem("Program Name", program.attributes.name);
                // setItem("Program Type", program.programType);
              }}>
                              {program.attributes.name}
                            </Link>
                          </li>)}
                    </ul>
                    <ul className="space-y-2 text-sm">
                      {filterByProgramTypes(selfPacedTypes, industry.id)?.map((program, itemIndex) => <li key={itemIndex} className="inline md:block md:before:content-none before:content-['•'] before:mx-2 opacity-60 hover:opacity-80 transition-opacity duration-300">
                            <Link href={`/in/${sanitizeUrlSegment(program.attributes.name)}${routeVariables.PREMIUMCOURSE[1]}${program.attributes.productId}`} onClick={() => {
                setItem("Industry", industry.attributes.name);
                setItem("Program Name", program.attributes.name);
                // setItem("Program Type", program.programType);
              }}>
                              {program.attributes.name}
                            </Link>
                          </li>)}
                    </ul>
                  </div>)}

          <hr className="block bg-white border-t opacity-20 md:hidden" />
          <div className="space-y-4">
            <h6 className="text-lg font-medium">Company</h6>
            <ul className="space-y-2 text-sm">
              <li className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                <Link href="/in/about-us" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">About Us</Link>
              </li>
              <li className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                <Link href="/careers" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">Careers</Link>
              </li>
              <li className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                <Link href="/in/contact-us" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h6 className="text-lg font-medium">Engage</h6>
            <ul className="space-y-2 text-sm">
              <li className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                <Link href="https://home.growthschool.io/b2b" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Upskill Your Team
                </Link>
              </li>
              <li className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                <Link href="https://forms.growthschool.io/custom/apply-as-a-mentor-at-growthschool" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                  Apply as Mentor
                </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <h6 className="text-lg font-medium">Industries</h6>
            <ul className="space-y-2 text-sm">
              {industries && industries.map((industry, itemIndex) => <li key={itemIndex} className="transition-opacity duration-300 opacity-60 hover:opacity-80">
                    <Link href={`/in/${sanitizeUrlSegment(industry.attributes.name)}${routeVariables.PREMIUMINDUSTRY}${industry.id}`}>
                      {industry.attributes.name}
                    </Link>
                  </li>)}
            </ul>
          </div>
        </div>

        <hr className="w-full h-px bg-white opacity-20" />
        <div className="section__footer-free-courses">
          <p className="section__footer-free-courses-heading">Free courses</p>
          <div className="section__footer-free-courses-content">
            {freeIndustries?.map((industry, index) => {
            const subIndustries = industry?.attributes?.subIndustries?.data;
            return <p key={index} className="section__footer-free-courses-content-group">
                  <span>{industry.attributes?.industryName}:</span>
                  {subIndustries?.map((subIndustry: any, itemIndex: number) => <Link key={itemIndex} href={`/in/${RouteStrings.DYNAMICROUTE(subIndustry?.attributes?.subIndustry ?? "", "SUBINDUSTRY", Number(subIndustry.id))}`}>
                      {subIndustry.attributes?.subIndustry}
                    </Link>)}
                </p>;
          })}
          </div>
        </div>

        <hr className="w-full h-px bg-white opacity-20" />

        <div className="flex flex-col items-start justify-between gap-2 text-white md:flex-row md:items-center md:gap-6">
          <div className="flex flex-wrap items-center gap-2 md:gap-4">
            <p className="text-sm opacity-60">
              © 2024 Sisinty Pvt. Ltd. All rights reserved
            </p>
            <div className="flex flex-wrap items-center gap-2 md:gap-4">
              <Link href="/privacy-policy" className="text-sm transition-opacity opacity-60 hover:opacity-80" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                · Privacy Policy
              </Link>
              <Link href="/refund-policy" className="text-sm transition-opacity opacity-60 hover:opacity-80" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                · Refund Policy
              </Link>
              <Link href="/terms-and-conditions" className="text-sm transition-opacity opacity-60 hover:opacity-80" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                · Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>

      {isNewsletterModal && <ModalComponent heading="Subscribe to our Top 1% Newsletter" subheading="Your Weekly Dose of Essential Insights" size="small" onClose={() => {
      setIsNewsletterModal(false);
      setFormErrors({});
    }}>
          <form className="section__modal-form" onSubmit={e => {
        e.preventDefault();
        validateForm(true, formValues);
      }}>
            <div className="section__modal-form-group">
              <label htmlFor="name">
                Full Name
                <span>*</span>
              </label>
              <input type="text" placeholder="John Doe" defaultValue={formValues?.fullName ?? ""} name="fullName" onChange={e => {
            const newValues = {
              ...formValues,
              ["fullName"]: e.target.value
            };
            setFormValues(newValues);
            if (formErrors.fullName) {
              validateForm(false, newValues);
            }
          }} />
              {formErrors.fullName && <span className="section__modal-form-group-error">
                  {formErrors.fullName}
                </span>}
            </div>
            <div className="section__modal-form-group">
              <label htmlFor="email">
                Email Address
                <span>*</span>
              </label>
              <input type="email" placeholder="john@growthschool.io" defaultValue={formValues?.email ?? ""} onChange={e => {
            const newValues = {
              ...formValues,
              ["email"]: e.target.value
            };
            setFormValues(newValues);
            if (formErrors.email) {
              validateForm(false, newValues);
            }
          }} />
              {formErrors.email && <span className="section__modal-form-group-error">
                  {formErrors.email}
                </span>}
            </div>
            <div className="section__modal-form-group">
              <label htmlFor="email">
                Industry
                <span>*</span>
              </label>
              <label className="dropdown">
                <div className="dd-buttons">
                  <div className={formValues.industry !== (industries ? industries[0].attributes.name : defaultIndustry) ? "industry-text-selected" : ""}>
                    {formValues.industry}
                  </div>
                </div>
                <input type="checkbox" className="dd-input" id="test" />
                <ul className="dd-menu">
                  {industries && industries.map((industry, itemIndex: number) => <li key={itemIndex} onClick={() => {
                const newValues = {
                  ...formValues,
                  ["industry"]: industry.attributes.name
                };
                setFormValues(newValues);
                if (formErrors.industry) {
                  validateForm(false, newValues);
                }
              }}>
                        {industry.attributes.name}
                      </li>)}
                </ul>
              </label>
              {formErrors.industry && <span className="section__modal-form-group-error" style={{
            marginTop: "-8px"
          }}>
                  {formErrors.industry}
                </span>}
            </div>

            <div className="section__modal-form-buttons">
              <button className="section__modal-form-submit" type={"submit"}>
                Subscribe
              </button>
              <Link href="https://home.growthschool.io/newsletter-vault" className="section__modal-form-link">
                Let me read it first
                <span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 13L10.5 10L7.5 7" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </Link>
            </div>
          </form>
        </ModalComponent>}

      {isNewsletterSuccessModal && <ModalComponent headingIcon={<svg style={{
      marginBottom: "14px"
    }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M36.6667 20.0007C36.6667 29.2054 29.2048 36.6673 20 36.6673C10.7953 36.6673 3.33337 29.2054 3.33337 20.0007C3.33337 10.7959 10.7953 3.33398 20 3.33398C29.2048 3.33398 36.6667 10.7959 36.6667 20.0007ZM28.0229 15.9694C28.5579 15.2204 28.3845 14.1795 27.6354 13.6445C26.8864 13.1094 25.8455 13.2829 25.3105 14.0319L18.1207 24.0977L14.5119 20.4888C13.861 19.838 12.8057 19.838 12.1549 20.4888C11.504 21.1397 11.504 22.195 12.1549 22.8459L17.1549 27.8459C17.5013 28.1923 17.9826 28.3687 18.4708 28.3283C18.9591 28.2879 19.4048 28.0347 19.6896 27.6361L28.0229 15.9694Z" fill="#33C375" />
            </svg>} heading="Welcome aboard!" subheading="Thrilled to have you on board with our newsletter!" size="small" onClose={() => {
      setIsNewsletterSuccessModal(false);
    }}>
          <div className="section__modal-success">
            {/* <button className="section__modal-form-submit" type={"submit"}>Read Past Newsletters</button> */}
          </div>
        </ModalComponent>}
    </footer>;
}